.order-calendar {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 1%;
  border: none;
}
.order-calendar button:enabled {
  border: 0.2px solid #eee;
  border-width: 1px 0 0 1px;
  min-height: 100px;
  position: relative;
  float:left;
}
.order-calendar button:enabled:nth-child(7n-6) {
  border-width: 1px 0 0 0;
}
.order-calendar button:enabled:hover {
  cursor: auto;
}
.order-calendar button:enabled abbr {
    position: absolute;
    top: 5px;
    right: 5px;
}
.order-calendar .react-calendar__tile {
  padding: 0;
}
.order-calendar div.custom {
    display: block;
    width: 100%;
    height: 100px;
    /*background-color:#f00;*/
}
.order-calendar ul {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  list-style-type: none;
  margin: 20px 0 0 0;
  padding: 0;
  text-align: left;
  font-size: 0.75rem;
  z-index: 10;
}
.order-calendar ul li {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  min-width: 1px;
  background-color: #eee;
  padding:3px 5px;
  margin: 3px;
  width: calc(100% - 6px);
}
.order-calendar ul li div div {
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
}
.order-calendar ul li:hover {
  cursor: pointer;
  background-color: #000;
  color: #fff;
}
.react-calendar__navigation button:enabled {
  border: 0.2px solid #eee;
  min-height: initial;
}
.react-calendar__navigation__prev2-button {
  /*border-width: 1px 1px 1px 1px !important;
  min-height: initial !important;*/
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.order-calendar .react-calendar__tile--active ul li {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  min-width: 1px;
  background-color: #000;
  padding:3px 5px;
  margin: 3px;
  width: calc(100% - 6px);
}

.order-calendar .react-calendar__tile--active ul li:hover {
  cursor: pointer;
  background-color: #eee;
  color: #000;
}